import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { AuthService } from '../auth.service';
// import * as converter from 'xml2js'
// import {xml2js} from 'xml2js'
import * as converter from 'xml-js';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  api = environment.apiUrl;

  constructor(private http: HttpClient, private xmlService: NgxXml2jsonService, private auth:AuthService) { }


  getSearchResults(filters) {
    let param = new HttpParams();
    let header = new HttpHeaders();
    let tokenKeyValue = this.auth.getAuthData();
    var tokenValue = '';
    console.log("tokenKeyValue::",tokenKeyValue)
    // if(tokenKeyValue == undefined || tokenKeyValue == null){
    //   tokenValue = tokenKeyValue.apiGardenToken;;
    // } else {
    //   tokenValue = environment.accesstokenPPE;
    // }
    if(tokenKeyValue == undefined || tokenKeyValue == null){
      tokenValue = environment.accesstokenPPE;
    } else{
      tokenValue = tokenKeyValue.apiGardenToken;
    }
    // header = header.append('Access-Control-Allow-Origin', '*');
    // header = header.append('Access-Control-Allow-Headers','*');
    // header = header.append('Accept','application/json');
    // header = header.append('Origin', 'http://localhost:4200');
    // header = header.append('Content-Type','application/json' );

    param = param.append('q', filters.q)
    param = param.append('num', filters.num)
    param = param.append('start', filters.start)
    param = param.append('selected', filters.selected)
    //param = param.append('X-AG-Access-Token',tokenValue);
    if(filters.entityType) {
      param = param.append('entityType', filters.entityType)
    }

    // console.log(param.get('entityType'));
    // http://10.184.75.38:8000
    return this.http.get(this.api +'/search', {
        // headers: header,
        params: param
      })
      .pipe(
        map(response => {
          return response
          // const resultsArry = [];
          // for(let key in response) {
          //   if(response.hasOwnProperty(key)) {
          //     resultsArry.push({...response[key], id: key})
          //   }
          // }
          // return resultsArry;
      }))
  }

  getViewApiResponse(viewApiResponseParams) {
    console.log("viewApiResponseParams::",viewApiResponseParams)
    let param = new HttpParams();
    let header = new HttpHeaders();
    let userRegistered= viewApiResponseParams.userLoggedIn ? 'registered' : 'notregistered';
    header = header.append('x-mdaas-user-type',userRegistered);
    param = param.append('format', viewApiResponseParams.format);
    header = header.append('X-AG-Access-Token',viewApiResponseParams.userToken);
    return this.http.get(this.api + '/entity/'+ viewApiResponseParams.query, {
        headers: header,
        params: param,
        responseType: 'text'
      })
      .pipe(
       )
  }

  getEntityDetail(id:string, userIsAuthenticated:boolean) {
    let header = new HttpHeaders();
	  let param = new HttpParams();
    let tokenKey = this.auth.getAuthData();
    let userRegistered= userIsAuthenticated ? 'registered' : 'notregistered';
    header = header.append('x-mdaas-user-type',userRegistered);
    console.log("userRegistered::",userRegistered)
    if(userRegistered =='notregistered'){
      header = header.append('X-Ag-Access-Token', environment.accesstokenPPE);
    } else {
      header = header.append('X-Ag-Access-Token',tokenKey.apiGardenToken);
    }

    return this.http.get(this.api + `/mdaas/getEntityById/${id}`,{
      headers:header,
	    params: param
    })
    .pipe(
      map(response => {
        return response
        // console.log("Entity", response);
        // const resultsArry = [];
        // for(let key in response) {
        //   if(response.hasOwnProperty(key)) {
        //     resultsArry.push({...response[key], id:key})
        //   }
        // }
        // return resultsArry;
      }))
  }

  getDownloadDetails() {
    let header = new HttpHeaders();
    header = header.append('x-mdaas-user-type','registered');
    return this.http.get( this.api + '/bulkDownload/metadata', {
      headers: header
    })
    .pipe(
      map(response => {
        console.log(response);
        const resultsArry = [];
        for(let key in response) {
          if(response.hasOwnProperty(key)) {
            resultsArry.push({...response[key], id:key})
          }
        }
        return resultsArry;
      })
    )
  }

  getTaggingResults(calaisParams) {
    let header = new HttpHeaders();
    // header = header.append('Content-Type', 'application/pdf');
    // header = header.append('Content-Type', 'text/raw');
    header = header.append('Content-Type', calaisParams.contentType);
    header = header.append('x-calais-contentClass', calaisParams.contentClass);
    //header = header.append('x-calais-licenseID', '05500E1A-2202-11E4-A1CF-866D2C647D00');
    header = header.append('x-calais-licenseID', 'BEBE46B6-BCD8-11E4-87D3-A1D975050C36');
    header = header.append('x-calais-pcontent','true');
    header = header.append('x-calais-EnableTickerExtraction', 'true');
    header = header.append('x-calais-useSlugline', 'true');
    header = header.append('x-calais-SuppressSocialTagsFin','true');

    if(calaisParams.contentType === 'application/pdf'){
      header = header.append("x-calais-pdftagzone", 'true');
    }

    header = header.append('outputFormat', 'application/json');

    return this.http.post(this.api + '/calais', calaisParams.Input, {
      headers: header,
      // responseType: 'text'
    }).
    pipe(
        map(response=> {
        const arr = [];
        for(let key in response){
          if(response.hasOwnProperty(key)){
            arr.push({...response[key], id:key});
          }
        }
        return arr;
      })  
    )
  }
  
  getViewRDFResults(calaisParams) {
    console.log(calaisParams);
    const userlogin = this.auth.getAuthData();
    console.log("userlogin check::", userlogin)
    console.log("local temp::", localStorage.getItem('token'))
    let header = new HttpHeaders();
    let param = new HttpParams();
    header = header.append('Content-Type', calaisParams.contentType);
    header = header.append('x-calais-contentClass', calaisParams.contentClass);
    //header = header.append('x-calais-licenseID', '05500E1A-2202-11E4-A1CF-866D2C647D00');
    header = header.append('x-calais-licenseID', 'BEBE46B6-BCD8-11E4-87D3-A1D975050C36');

    header = header.append('x-calais-pcontent','false');
    header = header.append('x-calais-EnableTickerExtraction', 'true');

    header = header.append('x-calais-useSlugline', 'true');
    header = header.append('x-calais-SuppressSocialTagsFin','true');
    if(localStorage.getItem('token')== null || localStorage.getItem('token')== undefined){
      header = header.append('X-Ag-Access-Token', environment.accesstokenPPE);
    } else {
      header = header.append('X-Ag-Access-Token',this.auth.getAuthToken());
    }
    if(calaisParams.contentType === 'application/pdf'){
      header = header.append("x-calais-pdftagzone", 'true');
    }
    header = header.append('outputFormat', 'xml/rdf');

    return this.http.post(this.api + '/calais', calaisParams.Input, {
      headers: header,
      params: param,
      responseType: 'text'
    }).
    pipe(

          )

    }

}
