import { Component,Input,OnInit } from '@angular/core';
import * as vkbeautify from 'vkbeautify';
import { Router } from '@angular/router';
import { SearchService } from '../shared/services/entitySearch/search.service';
import { TaggingModelService } from '../shared/tagging-model.service';
//import * as vkbeautify from 'vkbeautify';

@Component({
  selector: 'app-view-data',
  templateUrl: './view-data.component.html',
  styleUrls: ['./view-data.component.scss']
})
export class ViewDataComponent implements OnInit {
  
  isLoading: boolean = false;
  viewDataText:any;
  dataHeader:any;
  viewAPIResponse:any;

  constructor(private router:Router,
    private taggingDetail: TaggingModelService,
    private taggingService: SearchService
    ) { }

  ngOnInit() {
    console.log(" Inside view data component");

    const RDFData = JSON.parse(sessionStorage.getItem("RDFData"));
    this.dataHeader = RDFData.headerName;  
  
    if(RDFData.params[0]){
      if(RDFData.params[0].meta.contentType === 'application/pdf')
      RDFData.params[0].meta.contentType = 'text/raw';

    const calaisParams = {
      Input: RDFData.params[0].info.document,
      contentClass:RDFData.params[0].meta.contentClass,
      contentType:RDFData.params[0].meta.contentType
    }
    this.isLoading =true;
    this.getViewData(calaisParams);
     
  }else{
    this.isLoading = true;
    console.log("Calling getViewApiResponse")
    this.getViewApiResponse(RDFData.params);
  }
    
  }

  private getViewApiResponse(viewAPIResponse){
    this.taggingService.getViewApiResponse(viewAPIResponse).subscribe(data=>{
      this.isLoading = false;
      if(viewAPIResponse.format === 'json-ld') {
        data = JSON.parse(data);
        this.viewDataText = JSON.stringify(data,null, 2);
      } else {
       this.viewDataText = data;
      }
      this.viewDataText = vkbeautify.xml(data , '   ');
     // console.log( "beautified xml", this.viewDataText);
    })
  }
  
    private  getViewData(calaisParams){
    this.taggingService.getViewRDFResults(calaisParams).subscribe(data=>{
      this.isLoading = false;
      // let formatter = require('xml-formatter'); 
      //var formatter = require('html-formatter');
      //this.viewDataText = formatter.render(data); 
      this.viewDataText = vkbeautify.xml(data , '   ');
     // console.log( "beautified html", this.viewDataText); 
    }) 
  }  


}