import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  userIsAuthenticated = false;
  profile:any;
  private authListnerSub: Subscription;

  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.userIsAuthenticated = this.auth.getAuthStatus();
    this.authListnerSub = this.auth.getAuthListener().subscribe(isAuthenticated=>{
      this.userIsAuthenticated = isAuthenticated;
      if(this.userIsAuthenticated) {
        this.profile = this.auth.getUserData();    
      }
    })
    if(this.userIsAuthenticated) {
      this.profile = this.auth.getAuthData();
      console.log("Userdetails",this.profile);
      console.log(this.profile.token);
    }
  }

}
