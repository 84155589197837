export const environment = {
    production: false,
    apiUrl : 'https://ppe.permid.org/api',
    domain:'https://ppe.permid.org',
    registerUrl: "https://my.uat.refinitiv.com/content/mytr/en/register.html",
    updateProfileUrl:"https://my.uat.refinitiv.com/content/mytr/en/userprofile.html?&truid=##truid##",
    accesstokenQA : '0DSe9sOBBoHHopK6ViyRjMNRzu8IeEGi',
    accesstokenPPE : 'vlrruAeumozYEEcgWMlC8C0ADZj5wq8Q',
    accesstokenPROD : 'f1lG9qIPFRZdo1TNbqfMoywaIiF9mUYp'
  };
  